body{
    background-color: paleturquoise;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.container{
    margin: 0 auto;
    width: 960px;
}

.pt-3{
    padding-top:3em;
}

.word{
    margin: 0.3em 0;
    font-size: 6em;
    background-color: rgba(255,255,255, 0.5);
    color: darkcyan;
}

.text-center{
    text-align: center;
}

.p-3{
    padding: 1em;
}

.info{
    color: midnightblue;
}